define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common',
  'navigation',
  'sysconf',
  'preload',

  'common/table/tiny-table-view',
  'common/visual/visual',

  'text-loader!client/history/layout.tpl',
  'text-loader!client/history/row.tpl',
  'text-loader!client/history/table.tpl',

], function($, _, Backbone, darsan, common, navigation, sysconf, preload, TableView, visual, listTemplate, rowTemplate, tableTemplate)
{
  return Object.create(visual).extend({

    name: "history",
    title: "История",
    icon: "book",

    //////////////////////////////////////////////
    create: function (el, opt)
    {
      var me = this;
      visual.create.apply(me, arguments);
      me.renderFromTemplate(listTemplate,{});

      var HistModel = Backbone.Model.extend({
      });

      var HistList = Backbone.Collection.extend({
        model: HistModel,
      });

      me.collection = new HistList();

      return preload.ensure("currency")
      .done(() => {
        var table = new TableView({
          el: me.$el.find("div.table-responsive"),
          collection: me.collection,
          rowTemplate: rowTemplate,
          tableTemplate: tableTemplate,
          data: {
            module: me
          },
        });

        var toolbar = me.$el.find('.toolbar-days');

        _.each(me._fetchParams, function(params,name) {
          toolbar.find('.btn.' + name).on('click', function() {
            me._History(params);
          });
        });

        me.$el.find('.last100').on('click', function() {
          me._History({});
        });

        table.render();
      });
    },

    //////////////////////////////////////////////
    setState: function(state)
    {
      var me = this;

      // ничего не изменилось, отрисовывать не надо
      if (me.state && me.state.uid==state.uid) return;
      me.state = state;

      me.prefix = sysconf.domainByUid(state.uid) || config.domain;
      me.currency = preload.get(me.prefix,"currency");
      me._initHistory(state);
      me._History({});
    },

    _fetchParams: {
      last100: {},
      days30: {timespan: 2592000},
      days60: {timespan: 5184000},
      days90: {timespan: 7776000}
    },

    _History: function(params)
    {
      this.collection.fetch({reset: true, data: params});
    },

    _initHistory: function(state)
    {
      this.collection.url = darsan.makeUrl(this.prefix,"history", "/entity/client/" + state.uid);
      this.title = "История абонента " + state.uid;

      // external link to all history of this client with filters and whores
      this.$el.find("a#external-client-histoty").click(() => {
        navigation.gotoNewTab('/history/client/' + this.state.uid);
      });
    },

  });
});
                                