var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("HistoryTable", {
        attrs: {
          name: "client-history",
          showAdmin: true,
          showEntity: true,
          toolbar: _vm.toolbar,
          apiPath: `/entity/client/${_vm.state.uid}`,
          state: "",
          fetch: "simple"
        },
        on: { "toolbar-click": _vm.doToolbar }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }