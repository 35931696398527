<template>
  <div>
    <HistoryTable name="client-history" :showAdmin=true :showEntity=true :toolbar=toolbar :apiPath="`/entity/client/${state.uid}`" state=""
     fetch="simple" @toolbar-click=doToolbar
    />
  </div>
</template>

<script>
import * as nav from "navigation.js"
import HistoryTable from "common/visual/history/HistoryTable.vue"

export default {
  name: "ClientHistory",
  components: {HistoryTable},
  
  props: {
    state: Object,
  },
  
  data() 
  {
    return {
      toolbar: [
        {
          name: "full-history",
          icon: "fa-external-link",
          text: "Полная история",
        },
      ],
    }
  },
  
  methods: {
    doToolbar(ev)
    {
      if (ev.from=="full-history")
      {
        nav.gotoNewTab('/history/client/' + this.state.uid)
      }
    },
  },
}
</script>
